<template>
  <modal v-model="showModal" dimmer maskClose hideClose :hideFooter="false" title="求助" @on-ok="onOk" @on-cancel="onCancel">
    <div class="group-add-item">
      <span class="required">失败原因：</span>
      <y-input v-model="desc"></y-input>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
import YInput from '../common/input/input'
export default {
  components: { Modal, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: String
  },
  data () {
    return {
      showModal: this.show,
      desc: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      let str = this.desc || ''
      str = str.replace(/\s/g, '')
      if (str && str.length > 1) {
        this.$emit('on-ok', str)
        this.showModal = false
      } else {
        this.$parent.needFailedDesc()
      }
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.group-add-item {
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  input {
    width: 300px;
  }
  .required {
    position: relative;
    &::before {
      content: "*";
      color: red;
      transform: translate(-100%, 50%);
    }
  }
}
</style>
